import React from "react"
import Layout from "../components/layout"
import Seo from "../components/SEO"
import WeddingDate from "../components/wedding-date"
import place from "../content/images/place-24px.svg"
import wc from "../content/images/wc-24px.svg"
import restaurant from "../content/images/restaurant-24px.svg"
import running from "../content/images/directions_car-24px.svg"
import style from "../scss/timeline.module.scss";

export default () => {
  return (
    <Layout>
      <Seo />
      <h1>Timeline</h1>
      <WeddingDate style={{ marginBottom: "0.75em" }}/>
      <table className={style.timeline}>
        <tr>
          <td>3:30 pm</td>
            <td><img src={place} /></td>
          <td>Arrival</td>
        </tr>
        <tr>
          <td>4:00 pm</td>
            <td><img src={wc} /></td>
          <td>Ceremony</td>
        </tr>
        <tr>
          <td>4:30 pm</td>
          <td><img src={restaurant} /></td>
          <td>Party Time</td>
        </tr>
        <tr>
          <td>11:00 pm</td>
          <td><img src={running} /></td>
          <td>Departure</td>
        </tr>
      </table>
    </Layout>
  )
}

